export default [
{ value: null, text:"Seleccionar..."},
{departamento: "ATLANTICO",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - ATLANTICO", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "ANASWAYUU", value: "ANASWAYUU - ATLANTICO", text: "ANASWAYUU - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - ATLANTICO", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - ATLANTICO", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - ATLANTICO", text: "COMPENSAR E.P.S. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - ATLANTICO", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - ATLANTICO", text: "E.P.S. FAMISANAR LTDA. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - ATLANTICO", text: "E.P.S. SANITAS S.A. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - ATLANTICO", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - ATLANTICO", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - ATLANTICO", text: "FUNDACIÓN SALUD MIA EPS - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - ATLANTICO", text: "NUEVA EPS S.A. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - ATLANTICO", text: "SALUD TOTAL S.A. E.P.S. - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - ATLANTICO", text: "SALUDVIDA S.A. E.P.S - ATLANTICO"},
{departamento: "ATLANTICO",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - ATLANTICO", text: "SAVIA SALUD EPS - ATLANTICO"},
{departamento: "AMAZONAS",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - AMAZONAS", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - AMAZONAS", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - AMAZONAS", text: "CAPITAL SALUD - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - AMAZONAS", text: "COMPENSAR E.P.S. - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - AMAZONAS", text: "E.P.S. FAMISANAR LTDA. - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - AMAZONAS", text: "E.P.S. SANITAS S.A. - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - AMAZONAS", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - AMAZONAS", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "MALLAMAS", value: "MALLAMAS - AMAZONAS", text: "MALLAMAS - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - AMAZONAS", text: "NUEVA EPS S.A. - AMAZONAS"},
{departamento: "AMAZONAS",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - AMAZONAS", text: "SALUD TOTAL S.A. E.P.S. - AMAZONAS"},
{departamento: "ANTIOQUIA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - ANTIOQUIA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "ANASWAYUU", value: "ANASWAYUU - ANTIOQUIA", text: "ANASWAYUU - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - ANTIOQUIA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - ANTIOQUIA", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - ANTIOQUIA", text: "CAPITAL SALUD - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - ANTIOQUIA", text: "CAPRESOCA EPS - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - ANTIOQUIA", text: "COMFENALCO VALLE E.P.S. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - ANTIOQUIA", text: "COMPENSAR E.P.S. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - ANTIOQUIA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - ANTIOQUIA", text: "E.P.S. FAMISANAR LTDA. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - ANTIOQUIA", text: "E.P.S. SANITAS S.A. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "EPS CONVIDA", value: "EPS CONVIDA - ANTIOQUIA", text: "EPS CONVIDA - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - ANTIOQUIA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - ANTIOQUIA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - ANTIOQUIA", text: "FUNDACIÓN SALUD MIA EPS - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "MALLAMAS", value: "MALLAMAS - ANTIOQUIA", text: "MALLAMAS - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - ANTIOQUIA", text: "NUEVA EPS S.A. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - ANTIOQUIA", text: "SALUD TOTAL S.A. E.P.S. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "SALUDVIDA S.A. E.P.S.", value: "SALUDVIDA S.A. E.P.S. - ANTIOQUIA", text: "SALUDVIDA S.A. E.P.S. - ANTIOQUIA"},
{departamento: "ANTIOQUIA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - ANTIOQUIA", text: "SAVIA SALUD EPS - ANTIOQUIA"},
{departamento: "ARAUCA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - ARAUCA", text: "CAPITAL SALUD - ARAUCA"},
{departamento: "ARAUCA",		eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - ARAUCA", text: "CAPRESOCA EPS - ARAUCA"},
{departamento: "ARAUCA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - ARAUCA", text: "COMPENSAR E.P.S. - ARAUCA"},
{departamento: "ARAUCA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - ARAUCA", text: "E.P.S. FAMISANAR LTDA. - ARAUCA"},
{departamento: "ARAUCA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - ARAUCA", text: "E.P.S. SANITAS S.A. - ARAUCA"},
{departamento: "ARAUCA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - ARAUCA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - ARAUCA"},
{departamento: "ARAUCA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - ARAUCA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - ARAUCA"},
{departamento: "ARAUCA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - ARAUCA", text: "NUEVA EPS S.A. - ARAUCA"},
{departamento: "ARAUCA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - ARAUCA", text: "SALUD TOTAL S.A. E.P.S. - ARAUCA"},
{departamento: "ARAUCA",		eps: "SALUDVIDA S.A. E.P.S.", value: "SALUDVIDA S.A. E.P.S. - ARAUCA", text: "SALUDVIDA S.A. E.P.S. - ARAUCA"},
{departamento: "ARAUCA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - ARAUCA", text: "SAVIA SALUD EPS - ARAUCA"},
{departamento: "BOLIVAR",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - BOLIVAR", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - BOLIVAR", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - BOLIVAR", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - BOLIVAR", text: "CAPITAL SALUD - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - BOLIVAR", text: "CAPRESOCA EPS - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - BOLIVAR", text: "COMPENSAR E.P.S. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - BOLIVAR", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - BOLIVAR", text: "E.P.S. FAMISANAR LTDA. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - BOLIVAR", text: "E.P.S. SANITAS S.A. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "EPS CONVIDA", value: "EPS CONVIDA - BOLIVAR", text: "EPS CONVIDA - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - BOLIVAR", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - BOLIVAR", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - BOLIVAR", text: "NUEVA EPS S.A. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - BOLIVAR", text: "SALUD TOTAL S.A. E.P.S. - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "SALUDVIDA S.A .E.P.S", value: "SALUDVIDA S.A .E.P.S - BOLIVAR", text: "SALUDVIDA S.A .E.P.S - BOLIVAR"},
{departamento: "BOLIVAR",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - BOLIVAR", text: "SAVIA SALUD EPS - BOLIVAR"},
{departamento: "BOYACA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - BOYACA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - BOYACA"},
{departamento: "BOYACA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - BOYACA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - BOYACA"},
{departamento: "BOYACA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - BOYACA", text: "CAPITAL SALUD - BOYACA"},
{departamento: "BOYACA",		eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - BOYACA", text: "CAPRESOCA EPS - BOYACA"},
{departamento: "BOYACA",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - BOYACA", text: "COMFENALCO VALLE E.P.S. - BOYACA"},
{departamento: "BOYACA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - BOYACA", text: "COMPENSAR E.P.S. - BOYACA"},
{departamento: "BOYACA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - BOYACA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - BOYACA"},
{departamento: "BOYACA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - BOYACA", text: "E.P.S. FAMISANAR LTDA. - BOYACA"},
{departamento: "BOYACA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - BOYACA", text: "E.P.S. SANITAS S.A. - BOYACA"},
{departamento: "BOYACA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - BOYACA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - BOYACA"},
{departamento: "BOYACA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - BOYACA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - BOYACA"},
{departamento: "BOYACA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - BOYACA", text: "FUNDACIÓN SALUD MIA EPS - BOYACA"},
{departamento: "BOYACA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - BOYACA", text: "NUEVA EPS S.A. - BOYACA"},
{departamento: "BOYACA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - BOYACA", text: "SALUD TOTAL S.A. E.P.S. - BOYACA"},
{departamento: "BOYACA",		eps: "SALUDVIDA S.A .E.P.S", value: "SALUDVIDA S.A .E.P.S - BOYACA", text: "SALUDVIDA S.A .E.P.S - BOYACA"},
{departamento: "BOYACA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - BOYACA", text: "SAVIA SALUD EPS - BOYACA"},
{departamento: "CALDAS",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CALDAS", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CALDAS"},
{departamento: "CALDAS",	eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - CALDAS", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - CALDAS"},
{departamento: "CALDAS",	eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - CALDAS", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - CALDAS"},
{departamento: "CALDAS",	eps: "CAPITAL SALUD", value: "CAPITAL SALUD - CALDAS", text: "CAPITAL SALUD - CALDAS"},
{departamento: "CALDAS",	eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - CALDAS", text: "COMFENALCO VALLE E.P.S. - CALDAS"},
{departamento: "CALDAS",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - CALDAS", text: "COMPENSAR E.P.S. - CALDAS"},
{departamento: "CALDAS",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CALDAS", text: "E.P.S. FAMISANAR LTDA. - CALDAS"},
{departamento: "CALDAS",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CALDAS", text: "E.P.S. SANITAS S.A. - CALDAS"},
{departamento: "CALDAS",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CALDAS", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CALDAS"},
{departamento: "CALDAS",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CALDAS", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CALDAS"},
{departamento: "CALDAS",	eps: "MALLAMAS", value: "MALLAMAS - CALDAS", text: "MALLAMAS - CALDAS"},
{departamento: "CALDAS",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CALDAS", text: "NUEVA EPS S.A. - CALDAS"},
{departamento: "CALDAS",	eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - CALDAS", text: "PIJAOS SALUD EPSI - CALDAS"},
{departamento: "CALDAS",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CALDAS", text: "SALUD TOTAL S.A. E.P.S. - CALDAS"},
{departamento: "CALDAS",	eps: "SALUDVIDA S.A .E.P.S", value: "SALUDVIDA S.A .E.P.S - CALDAS", text: "SALUDVIDA S.A .E.P.S - CALDAS"},
{departamento: "CALDAS",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CALDAS", text: "SAVIA SALUD EPS - CALDAS"},
{departamento: "CAQUETA",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CAQUETA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CAQUETA"},
{departamento: "CAQUETA",	eps: "CAPITAL SALUD", value: "CAPITAL SALUD - CAQUETA", text: "CAPITAL SALUD - CAQUETA"},
{departamento: "CAQUETA",	eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - CAQUETA", text: "COMFENALCO VALLE E.P.S. - CAQUETA"},
{departamento: "CAQUETA",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - CAQUETA", text: "COMPENSAR E.P.S. - CAQUETA"},
{departamento: "CAQUETA",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CAQUETA", text: "E.P.S. FAMISANAR LTDA. - CAQUETA"},
{departamento: "CAQUETA",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CAQUETA", text: "E.P.S. SANITAS S.A. - CAQUETA"},
{departamento: "CAQUETA",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CAQUETA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CAQUETA"},
{departamento: "CAQUETA",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CAQUETA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CAQUETA"},
{departamento: "CAQUETA",	eps: "MALLAMAS", value: "MALLAMAS - CAQUETA", text: "MALLAMAS - CAQUETA"},
{departamento: "CAQUETA",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CAQUETA", text: "NUEVA EPS S.A. - CAQUETA"},
{departamento: "CAQUETA",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CAQUETA", text: "SALUD TOTAL S.A. E.P.S. - CAQUETA"},
{departamento: "CAQUETA",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CAQUETA", text: "SAVIA SALUD EPS - CAQUETA"},
{departamento: "CAUCA",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CAUCA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CAUCA"},
{departamento: "CAUCA",	eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - CAUCA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - CAUCA"},
{departamento: "CAUCA",	eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - CAUCA", text: "COMFENALCO VALLE E.P.S. - CAUCA"},
{departamento: "CAUCA",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - CAUCA", text: "COMPENSAR E.P.S. - CAUCA"},
{departamento: "CAUCA",	eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CAUCA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CAUCA"},
{departamento: "CAUCA",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CAUCA", text: "E.P.S. FAMISANAR LTDA. - CAUCA"},
{departamento: "CAUCA",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CAUCA", text: "E.P.S. SANITAS S.A. - CAUCA"},
{departamento: "CAUCA",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CAUCA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CAUCA"},
{departamento: "CAUCA",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CAUCA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CAUCA"},
{departamento: "CAUCA",	eps: "MALLAMAS", value: "MALLAMAS - CAUCA", text: "MALLAMAS - CAUCA"},
{departamento: "CAUCA",	eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - CAUCA", text: "FUNDACIÓN SALUD MIA EPS - CAUCA"},
{departamento: "CAUCA",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CAUCA", text: "NUEVA EPS S.A. - CAUCA"},
{departamento: "CAUCA",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CAUCA", text: "SALUD TOTAL S.A. E.P.S. - CAUCA"},
{departamento: "CAUCA",	eps: "SALUDVIDA S.A .E.P.S", value: "SALUDVIDA S.A .E.P.S - CAUCA", text: "SALUDVIDA S.A .E.P.S - CAUCA"},
{departamento: "CAUCA",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CAUCA", text: "SAVIA SALUD EPS - CAUCA"},
{departamento: "CESAR",	eps: "ANASWAYUU", value: "ANASWAYUU - CESAR", text: "ANASWAYUU - CESAR"},
{departamento: "CESAR",	eps: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI", value: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - CESAR", text: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - CESAR"},
{departamento: "CESAR",	eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - CESAR", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - CESAR"},
{departamento: "CESAR",	eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - CESAR", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - CESAR"},
{departamento: "CESAR",	eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - CESAR", text: "CAPRESOCA EPS - CESAR"},
{departamento: "CESAR",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - CESAR", text: "COMPENSAR E.P.S. - CESAR"},
{departamento: "CESAR",	eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CESAR", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CESAR"},
{departamento: "CESAR",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CESAR", text: "E.P.S. FAMISANAR LTDA. - CESAR"},
{departamento: "CESAR",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CESAR", text: "E.P.S. SANITAS S.A. - CESAR"},
{departamento: "CESAR",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CESAR", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CESAR"},
{departamento: "CESAR",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CESAR", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CESAR"},
{departamento: "CESAR",	eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - CESAR", text: "FUNDACIÓN SALUD MIA EPS - CESAR"},
{departamento: "CESAR",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CESAR", text: "NUEVA EPS S.A. - CESAR"},
{departamento: "CESAR",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CESAR", text: "SALUD TOTAL S.A. E.P.S. - CESAR"},
{departamento: "CESAR",	eps: "SALUDVIDA S.A .E.P.S", value: "SALUDVIDA S.A .E.P.S - CESAR", text: "SALUDVIDA S.A .E.P.S - CESAR"},
{departamento: "CESAR",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CESAR", text: "SAVIA SALUD EPS - CESAR"},
{departamento: "CHOCO",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - CHOCO", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - CHOCO"},
{departamento: "CHOCO",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - CHOCO", text: "CAPITAL SALUD - CHOCO"},
{departamento: "CHOCO",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CHOCO", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CHOCO"},
{departamento: "CHOCO",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CHOCO", text: "E.P.S. FAMISANAR LTDA. - CHOCO"},
{departamento: "CHOCO",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CHOCO", text: "E.P.S. SANITAS S.A. - CHOCO"},
{departamento: "CHOCO",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CHOCO", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CHOCO"},
{departamento: "CHOCO",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CHOCO", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CHOCO"},
{departamento: "CHOCO",		eps: "MALLAMAS", value: "MALLAMAS - CHOCO", text: "MALLAMAS - CHOCO"},
{departamento: "CHOCO",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CHOCO", text: "NUEVA EPS S.A. - CHOCO"},
{departamento: "CHOCO",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CHOCO", text: "SALUD TOTAL S.A. E.P.S. - CHOCO"},
{departamento: "CHOCO",		eps: "SALUDVIDA S.A. E.P.S.", value: "SALUDVIDA S.A. E.P.S. - CHOCO", text: "SALUDVIDA S.A. E.P.S. - CHOCO"},
{departamento: "CHOCO",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CHOCO", text: "SAVIA SALUD EPS - CHOCO"},
{departamento: "CORDOBA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CORDOBA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CORDOBA"},
{departamento: "CORDOBA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - CORDOBA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - CORDOBA"},
{departamento: "CORDOBA",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - CORDOBA", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - CORDOBA"},
{departamento: "CORDOBA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - CORDOBA", text: "CAPITAL SALUD - CORDOBA"},
{departamento: "CORDOBA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - CORDOBA", text: "COMPENSAR E.P.S. - CORDOBA"},
{departamento: "CORDOBA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CORDOBA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CORDOBA"},
{departamento: "CORDOBA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CORDOBA", text: "E.P.S. FAMISANAR LTDA. - CORDOBA"},
{departamento: "CORDOBA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CORDOBA", text: "E.P.S. SANITAS S.A. - CORDOBA"},
{departamento: "CORDOBA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CORDOBA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CORDOBA"},
{departamento: "CORDOBA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CORDOBA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CORDOBA"},
{departamento: "CORDOBA",		eps: "MALLAMAS", value: "MALLAMAS - CORDOBA", text: "MALLAMAS - CORDOBA"},
{departamento: "CORDOBA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - CORDOBA", text: "FUNDACIÓN SALUD MIA EPS - CORDOBA"},
{departamento: "CORDOBA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CORDOBA", text: "NUEVA EPS S.A. - CORDOBA"},
{departamento: "CORDOBA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CORDOBA", text: "SALUD TOTAL S.A. E.P.S. - CORDOBA"},
{departamento: "CORDOBA",		eps: "SALUDVIDA S.A .E.P.S", value: "SALUDVIDA S.A .E.P.S - CORDOBA", text: "SALUDVIDA S.A .E.P.S - CORDOBA"},
{departamento: "CORDOBA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CORDOBA", text: "SAVIA SALUD EPS - CORDOBA"},
{departamento: "CUNDINAMARCA",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CUNDINAMARCA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - CUNDINAMARCA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "CAPITAL SALUD", value: "CAPITAL SALUD - CUNDINAMARCA", text: "CAPITAL SALUD - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - CUNDINAMARCA", text: "CAPRESOCA EPS - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - CUNDINAMARCA", text: "COMFENALCO VALLE E.P.S. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - CUNDINAMARCA", text: "COMPENSAR E.P.S. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CUNDINAMARCA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - CUNDINAMARCA", text: "E.P.S. FAMISANAR LTDA. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - CUNDINAMARCA", text: "E.P.S. SANITAS S.A. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "EPS CONVIDA", value: "EPS CONVIDA - CUNDINAMARCA", text: "EPS CONVIDA - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CUNDINAMARCA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CUNDINAMARCA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "MALLAMAS", value: "MALLAMAS - CUNDINAMARCA", text: "MALLAMAS - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - CUNDINAMARCA", text: "NUEVA EPS S.A. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - CUNDINAMARCA", text: "PIJAOS SALUD EPSI - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - CUNDINAMARCA", text: "SALUD TOTAL S.A. E.P.S. - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - CUNDINAMARCA", text: "SALUDVIDA S.A. E.P.S - CUNDINAMARCA"},
{departamento: "CUNDINAMARCA",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - CUNDINAMARCA", text: "SAVIA SALUD EPS - CUNDINAMARCA"},
{departamento: "GUAINIA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - GUAINIA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - GUAINIA"},
{departamento: "GUAINIA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - GUAINIA", text: "E.P.S. SANITAS S.A. - GUAINIA"},
{departamento: "GUAINIA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - GUAINIA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - GUAINIA"},
{departamento: "GUAINIA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - GUAINIA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - GUAINIA"},
{departamento: "GUAINIA",		eps: "MALLAMAS", value: "MALLAMAS - GUAINIA", text: "MALLAMAS - GUAINIA"},
{departamento: "GUAINIA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - GUAINIA", text: "NUEVA EPS S.A. - GUAINIA"},
{departamento: "GUAJIRA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - GUAJIRA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "ANASWAYUU", value: "ANASWAYUU - GUAJIRA", text: "ANASWAYUU - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI", value: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - GUAJIRA", text: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - GUAJIRA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - GUAJIRA", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - GUAJIRA", text: "CAPITAL SALUD - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - GUAJIRA", text: "COMPENSAR E.P.S. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - GUAJIRA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - GUAJIRA", text: "E.P.S. FAMISANAR LTDA. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - GUAJIRA", text: "E.P.S. SANITAS S.A. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - GUAJIRA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - GUAJIRA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - GUAJIRA", text: "FUNDACIÓN SALUD MIA EPS - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - GUAJIRA", text: "NUEVA EPS S.A. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - GUAJIRA", text: "SALUD TOTAL S.A. E.P.S. - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - GUAJIRA", text: "SALUDVIDA S.A. E.P.S - GUAJIRA"},
{departamento: "GUAJIRA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - GUAJIRA", text: "SAVIA SALUD EPS - GUAJIRA"},
{departamento: "GUAVIARE",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - GUAVIARE", text: "COMPENSAR E.P.S. - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - GUAVIARE", text: "E.P.S. FAMISANAR LTDA. - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - GUAVIARE", text: "E.P.S. SANITAS S.A. - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - GUAVIARE", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - GUAVIARE", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - GUAVIARE", text: "NUEVA EPS S.A. - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - GUAVIARE", text: "SALUD TOTAL S.A. E.P.S. - GUAVIARE"},
{departamento: "GUAVIARE",	eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - GUAVIARE", text: "SALUDVIDA S.A. E.P.S - GUAVIARE"},
{departamento: "HUILA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - HUILA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - HUILA"},
{departamento: "HUILA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - HUILA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - HUILA"},
{departamento: "HUILA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - HUILA", text: "CAPITAL SALUD - HUILA"},
{departamento: "HUILA",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - HUILA", text: "COMFENALCO VALLE E.P.S. - HUILA"},
{departamento: "HUILA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - HUILA", text: "COMPENSAR E.P.S. - HUILA"},
{departamento: "HUILA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - HUILA", text: "E.P.S. FAMISANAR LTDA. - HUILA"},
{departamento: "HUILA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - HUILA", text: "E.P.S. SANITAS S.A. - HUILA"},
{departamento: "HUILA",		eps: "EPS CONVIDA", value: "EPS CONVIDA - HUILA", text: "EPS CONVIDA - HUILA"},
{departamento: "HUILA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - HUILA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - HUILA"},
{departamento: "HUILA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - HUILA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - HUILA"},
{departamento: "HUILA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - HUILA", text: "FUNDACIÓN SALUD MIA EPS - HUILA"},
{departamento: "HUILA",		eps: "MALLAMAS", value: "MALLAMAS - HUILA", text: "MALLAMAS - HUILA"},
{departamento: "HUILA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - HUILA", text: "NUEVA EPS S.A. - HUILA"},
{departamento: "HUILA",		eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - HUILA", text: "PIJAOS SALUD EPSI - HUILA"},
{departamento: "HUILA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - HUILA", text: "SALUD TOTAL S.A. E.P.S. - HUILA"},
{departamento: "HUILA",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - HUILA", text: "SALUDVIDA S.A. E.P.S - HUILA"},
{departamento: "HUILA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - HUILA", text: "SAVIA SALUD EPS - HUILA"},
{departamento: "MAGDALENA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - MAGDALENA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI", value: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - MAGDALENA", text: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - MAGDALENA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - MAGDALENA", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - MAGDALENA", text: "CAPITAL SALUD - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - MAGDALENA", text: "COMPENSAR E.P.S. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - MAGDALENA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - MAGDALENA", text: "E.P.S. FAMISANAR LTDA. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - MAGDALENA", text: "E.P.S. SANITAS S.A. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - MAGDALENA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - MAGDALENA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - MAGDALENA", text: "FUNDACIÓN SALUD MIA EPS - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - MAGDALENA", text: "NUEVA EPS S.A. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - MAGDALENA", text: "SALUD TOTAL S.A. E.P.S. - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - MAGDALENA", text: "SALUDVIDA S.A. E.P.S - MAGDALENA"},
{departamento: "MAGDALENA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - MAGDALENA", text: "SAVIA SALUD EPS - MAGDALENA"},
{departamento: "META",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - META", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - META"},
{departamento: "META",		eps: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI", value: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - META", text: "ASOCIACIÓN INDÍGENA DEL CESAR Y LA GUAJIRA DUSAKAWI - META"},
{departamento: "META",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - META", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - META"},
{departamento: "META",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - META", text: "CAPITAL SALUD - META"},
{departamento: "META",		eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - META", text: "CAPRESOCA EPS - META"},
{departamento: "META",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - META", text: "COMFENALCO VALLE E.P.S. - META"},
{departamento: "META",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - META", text: "COMPENSAR E.P.S. - META"},
{departamento: "META",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - META", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - META"},
{departamento: "META",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - META", text: "E.P.S. FAMISANAR LTDA. - META"},
{departamento: "META",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - META", text: "E.P.S. SANITAS S.A. - META"},
{departamento: "META",		eps: "EPS CONVIDA", value: "EPS CONVIDA - META", text: "EPS CONVIDA - META"},
{departamento: "META",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - META", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - META"},
{departamento: "META",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - META", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - META"},
{departamento: "META",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - META", text: "FUNDACIÓN SALUD MIA EPS - META"},
{departamento: "META",		eps: "MALLAMAS", value: "MALLAMAS - META", text: "MALLAMAS - META"},
{departamento: "META",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - META", text: "NUEVA EPS S.A. - META"},
{departamento: "META",		eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - META", text: "PIJAOS SALUD EPSI - META"},
{departamento: "META",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - META", text: "SALUD TOTAL S.A. E.P.S. - META"},
{departamento: "META",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - META", text: "SALUDVIDA S.A. E.P.S - META"},
{departamento: "META",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - META", text: "SAVIA SALUD EPS - META"},
{departamento: "NARIÑO",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - NARIÑO", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - NARIÑO"},
{departamento: "NARIÑO",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - NARIÑO", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - NARIÑO"},
{departamento: "NARIÑO",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - NARIÑO", text: "CAPITAL SALUD - NARIÑO"},
{departamento: "NARIÑO",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - NARIÑO", text: "COMPENSAR E.P.S. - NARIÑO"},
{departamento: "NARIÑO",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - NARIÑO", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - NARIÑO"},
{departamento: "NARIÑO",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - NARIÑO", text: "E.P.S. FAMISANAR LTDA. - NARIÑO"},
{departamento: "NARIÑO",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - NARIÑO", text: "E.P.S. SANITAS S.A. - NARIÑO"},
{departamento: "NARIÑO",		eps: "EPS CONVIDA", value: "EPS CONVIDA - NARIÑO", text: "EPS CONVIDA - NARIÑO"},
{departamento: "NARIÑO",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - NARIÑO", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - NARIÑO"},
{departamento: "NARIÑO",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - NARIÑO", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - NARIÑO"},
{departamento: "NARIÑO",		eps: "MALLAMAS", value: "MALLAMAS - NARIÑO", text: "MALLAMAS - NARIÑO"},
{departamento: "NARIÑO",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - NARIÑO", text: "NUEVA EPS S.A. - NARIÑO"},
{departamento: "NARIÑO",		eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - NARIÑO", text: "PIJAOS SALUD EPSI - NARIÑO"},
{departamento: "NARIÑO",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - NARIÑO", text: "SALUD TOTAL S.A. E.P.S. - NARIÑO"},
{departamento: "NARIÑO",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - NARIÑO", text: "SALUDVIDA S.A. E.P.S - NARIÑO"},
{departamento: "NARIÑO",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - NARIÑO", text: "SAVIA SALUD EPS - NARIÑO"},
{departamento: "NORTE DE SANTANDER",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - NORTE DE SANTANDER", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "CAPITAL SALUD", value: "CAPITAL SALUD - NORTE DE SANTANDER", text: "CAPITAL SALUD - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - NORTE DE SANTANDER", text: "CAPRESOCA EPS - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - NORTE DE SANTANDER", text: "COMPENSAR E.P.S. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - NORTE DE SANTANDER", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - NORTE DE SANTANDER", text: "E.P.S. FAMISANAR LTDA. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - NORTE DE SANTANDER", text: "E.P.S. SANITAS S.A. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - NORTE DE SANTANDER", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - NORTE DE SANTANDER", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - NORTE DE SANTANDER", text: "FUNDACIÓN SALUD MIA EPS - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "MALLAMAS", value: "MALLAMAS - NORTE DE SANTANDER", text: "MALLAMAS - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - NORTE DE SANTANDER", text: "NUEVA EPS S.A. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - NORTE DE SANTANDER", text: "SALUD TOTAL S.A. E.P.S. - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - NORTE DE SANTANDER", text: "SALUDVIDA S.A. E.P.S - NORTE DE SANTANDER"},
{departamento: "NORTE DE SANTANDER",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - NORTE DE SANTANDER", text: "SAVIA SALUD EPS - NORTE DE SANTANDER"},
{departamento: "PUTUMAYO",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - PUTUMAYO", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - PUTUMAYO", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - PUTUMAYO", text: "COMFENALCO VALLE E.P.S. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - PUTUMAYO", text: "COMPENSAR E.P.S. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - PUTUMAYO", text: "E.P.S. FAMISANAR LTDA. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - PUTUMAYO", text: "E.P.S. SANITAS S.A. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - PUTUMAYO", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - PUTUMAYO", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "MALLAMAS", value: "MALLAMAS - PUTUMAYO", text: "MALLAMAS - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - PUTUMAYO", text: "NUEVA EPS S.A. - PUTUMAYO"},
{departamento: "PUTUMAYO",		eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - PUTUMAYO", text: "PIJAOS SALUD EPSI - PUTUMAYO"},
{departamento: "QUINDIO",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - QUINDIO", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - QUINDIO"},
{departamento: "QUINDIO",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - QUINDIO", text: "CAPITAL SALUD - QUINDIO"},
{departamento: "QUINDIO",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - QUINDIO", text: "COMPENSAR E.P.S. - QUINDIO"},
{departamento: "QUINDIO",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - QUINDIO", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - QUINDIO"},
{departamento: "QUINDIO",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - QUINDIO", text: "E.P.S. FAMISANAR LTDA. - QUINDIO"},
{departamento: "QUINDIO",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - QUINDIO", text: "E.P.S. SANITAS S.A. - QUINDIO"},
{departamento: "QUINDIO",		eps: "EPS CONVIDA", value: "EPS CONVIDA - QUINDIO", text: "EPS CONVIDA - QUINDIO"},
{departamento: "QUINDIO",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - QUINDIO", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - QUINDIO"},
{departamento: "QUINDIO",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - QUINDIO", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - QUINDIO"},
{departamento: "QUINDIO",		eps: "MALLAMAS", value: "MALLAMAS - QUINDIO", text: "MALLAMAS - QUINDIO"},
{departamento: "QUINDIO",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - QUINDIO", text: "NUEVA EPS S.A. - QUINDIO"},
{departamento: "QUINDIO",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - QUINDIO", text: "SALUD TOTAL S.A. E.P.S. - QUINDIO"},
{departamento: "QUINDIO",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - QUINDIO", text: "SALUDVIDA S.A. E.P.S - QUINDIO"},
{departamento: "QUINDIO",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - QUINDIO", text: "SAVIA SALUD EPS - QUINDIO"},
{departamento: "RISARALDA",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - RISARALDA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - RISARALDA"},
{departamento: "RISARALDA",	eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - RISARALDA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - RISARALDA"},
{departamento: "RISARALDA",	eps: "CAPITAL SALUD", value: "CAPITAL SALUD - RISARALDA", text: "CAPITAL SALUD - RISARALDA"},
{departamento: "RISARALDA",	eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - RISARALDA", text: "CAPRESOCA EPS - RISARALDA"},
{departamento: "RISARALDA",	eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - RISARALDA", text: "COMFENALCO VALLE E.P.S. - RISARALDA"},
{departamento: "RISARALDA",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - RISARALDA", text: "COMPENSAR E.P.S. - RISARALDA"},
{departamento: "RISARALDA",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - RISARALDA", text: "E.P.S. FAMISANAR LTDA. - RISARALDA"},
{departamento: "RISARALDA",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - RISARALDA", text: "E.P.S. SANITAS S.A. - RISARALDA"},
{departamento: "RISARALDA",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - RISARALDA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - RISARALDA"},
{departamento: "RISARALDA",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - RISARALDA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - RISARALDA"},
{departamento: "RISARALDA",	eps: "MALLAMAS", value: "MALLAMAS - RISARALDA", text: "MALLAMAS - RISARALDA"},
{departamento: "RISARALDA",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - RISARALDA", text: "NUEVA EPS S.A. - RISARALDA"},
{departamento: "RISARALDA",	eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - RISARALDA", text: "PIJAOS SALUD EPSI - RISARALDA"},
{departamento: "RISARALDA",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - RISARALDA", text: "SALUD TOTAL S.A. E.P.S. - RISARALDA"},
{departamento: "RISARALDA",	eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - RISARALDA", text: "SALUDVIDA S.A. E.P.S - RISARALDA"},
{departamento: "RISARALDA",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - RISARALDA", text: "SAVIA SALUD EPS - RISARALDA"},
{departamento: "SAN ANDRÉS",		eps: "EPS S.O.S. S.A.", value: "EPS S.O.S. S.A. - SAN ANDRÉS", text: "EPS S.O.S. S.A. - SAN ANDRÉS"},
{departamento: "SAN ANDRÉS",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - SAN ANDRÉS", text: "E.P.S. SANITAS S.A. - SAN ANDRÉS"},
{departamento: "SAN ANDRÉS",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - SAN ANDRÉS", text: "NUEVA EPS S.A. - SAN ANDRÉS"},
{departamento: "SAN ANDRÉS",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - SAN", text: "SALUD TOTAL S.A. E.P.S. - SAN"},
{departamento: "SANTANDER",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - SANTANDER", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - SANTANDER"},
{departamento: "SANTANDER",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - SANTANDER", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - SANTANDER"},
{departamento: "SANTANDER",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - SANTANDER", text: "CAPITAL SALUD - SANTANDER"},
{departamento: "SANTANDER",		eps: "CAPRESOCA EPS", value: "CAPRESOCA EPS - SANTANDER", text: "CAPRESOCA EPS - SANTANDER"},
{departamento: "SANTANDER",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - SANTANDER", text: "COMPENSAR E.P.S. - SANTANDER"},
{departamento: "SANTANDER",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - SANTANDER", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - SANTANDER"},
{departamento: "SANTANDER",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - SANTANDER", text: "E.P.S. FAMISANAR LTDA. - SANTANDER"},
{departamento: "SANTANDER",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - SANTANDER", text: "E.P.S. SANITAS S.A. - SANTANDER"},
{departamento: "SANTANDER",		eps: "EPS CONVIDA", value: "EPS CONVIDA - SANTANDER", text: "EPS CONVIDA - SANTANDER"},
{departamento: "SANTANDER",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - SANTANDER", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - SANTANDER"},
{departamento: "SANTANDER",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - SANTANDER", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - SANTANDER"},
{departamento: "SANTANDER",		eps: "FUNDACIÓN SALUD MIA", value: "FUNDACIÓN SALUD MIA - SANTANDER", text: "FUNDACIÓN SALUD MIA - SANTANDER"},
{departamento: "SANTANDER",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - SANTANDER", text: "NUEVA EPS S.A. - SANTANDER"},
{departamento: "SANTANDER",		eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - SANTANDER", text: "PIJAOS SALUD EPSI - SANTANDER"},
{departamento: "SANTANDER",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - SANTANDER", text: "SALUD TOTAL S.A. E.P.S. - SANTANDER"},
{departamento: "SANTANDER",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - SANTANDER", text: "SALUDVIDA S.A. E.P.S - SANTANDER"},
{departamento: "SANTANDER",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - SANTANDER", text: "SAVIA SALUD EPS - SANTANDER"},
{departamento: "SUCRE",	eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - SUCRE", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - SUCRE"},
{departamento: "SUCRE",	eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - SUCRE", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - SUCRE"},
{departamento: "SUCRE",	eps: "CAPITAL SALUD", value: "CAPITAL SALUD - SUCRE", text: "CAPITAL SALUD - SUCRE"},
{departamento: "SUCRE",	eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - SUCRE", text: "COMPENSAR E.P.S. - SUCRE"},
{departamento: "SUCRE",	eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - SUCRE", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - SUCRE"},
{departamento: "SUCRE",	eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - SUCRE", text: "E.P.S. FAMISANAR LTDA. - SUCRE"},
{departamento: "SUCRE",	eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - SUCRE", text: "E.P.S. SANITAS S.A. - SUCRE"},
{departamento: "SUCRE",	eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - SUCRE", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - SUCRE"},
{departamento: "SUCRE",	eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - SUCRE", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - SUCRE"},
{departamento: "SUCRE",	eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - SUCRE", text: "NUEVA EPS S.A. - SUCRE"},
{departamento: "SUCRE",	eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - SUCRE", text: "SALUD TOTAL S.A. E.P.S. - SUCRE"},
{departamento: "SUCRE",	eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - SUCRE", text: "SALUDVIDA S.A. E.P.S - SUCRE"},
{departamento: "SUCRE",	eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - SUCRE", text: "SAVIA SALUD EPS - SUCRE"},
{departamento: "TOLIMA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - TOLIMA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - TOLIMA"},
{departamento: "TOLIMA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - TOLIMA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - TOLIMA"},
{departamento: "TOLIMA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - TOLIMA", text: "CAPITAL SALUD - TOLIMA"},
{departamento: "TOLIMA",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - TOLIMA", text: "COMFENALCO VALLE E.P.S. - TOLIMA"},
{departamento: "TOLIMA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - TOLIMA", text: "COMPENSAR E.P.S. - TOLIMA"},
{departamento: "TOLIMA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - TOLIMA", text: "E.P.S. FAMISANAR LTDA. - TOLIMA"},
{departamento: "TOLIMA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - TOLIMA", text: "E.P.S. SANITAS S.A. - TOLIMA"},
{departamento: "TOLIMA",		eps: "EPS CONVIDA", value: "EPS CONVIDA - TOLIMA", text: "EPS CONVIDA - TOLIMA"},
{departamento: "TOLIMA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - TOLIMA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - TOLIMA"},
{departamento: "TOLIMA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - TOLIMA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - TOLIMA"},
{departamento: "TOLIMA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - TOLIMA", text: "FUNDACIÓN SALUD MIA EPS - TOLIMA"},
{departamento: "TOLIMA",		eps: "MALLAMAS", value: "MALLAMAS - TOLIMA", text: "MALLAMAS - TOLIMA"},
{departamento: "TOLIMA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - TOLIMA", text: "NUEVA EPS S.A. - TOLIMA"},
{departamento: "TOLIMA",		eps: "PIJAOS SALUD EPSI", value: "PIJAOS SALUD EPSI - TOLIMA", text: "PIJAOS SALUD EPSI - TOLIMA"},
{departamento: "TOLIMA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - TOLIMA", text: "SALUD TOTAL S.A. E.P.S. - TOLIMA"},
{departamento: "TOLIMA",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - TOLIMA", text: "SALUDVIDA S.A. E.P.S - TOLIMA"},
{departamento: "TOLIMA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - TOLIMA", text: "SAVIA SALUD EPS - TOLIMA"},
{departamento: "VALLE DEL CAUCA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - VALLE DEL CAUCA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "ASOCIACIÓN INDÍGENA DEL CAUCA", value: "ASOCIACIÓN INDÍGENA DEL CAUCA - VALLE DEL CAUCA", text: "ASOCIACIÓN INDÍGENA DEL CAUCA - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS", value: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - VALLE DEL CAUCA", text: "ASOCIACION MUTUAL SER EMPRESA SOLIDARIA DE SALUD EPS - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "CAPITAL SALUD", value: "CAPITAL SALUD - VALLE DEL CAUCA", text: "CAPITAL SALUD - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "COMFENALCO VALLE E.P.S.", value: "COMFENALCO VALLE E.P.S. - VALLE DEL CAUCA", text: "COMFENALCO VALLE E.P.S. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "COMPENSAR E.P.S.", value: "COMPENSAR E.P.S. - VALLE DEL CAUCA", text: "COMPENSAR E.P.S. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA", value: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - VALLE DEL CAUCA", text: "COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - VALLE DEL CAUCA", text: "E.P.S. FAMISANAR LTDA. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - VALLE DEL CAUCA", text: "E.P.S. SANITAS S.A. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "EPS CONVIDA", value: "EPS CONVIDA - VALLE DEL CAUCA", text: "EPS CONVIDA - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - VALLE DEL CAUCA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - VALLE DEL CAUCA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "FUNDACIÓN SALUD MIA EPS", value: "FUNDACIÓN SALUD MIA EPS - VALLE DEL CAUCA", text: "FUNDACIÓN SALUD MIA EPS - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "MALLAMAS", value: "MALLAMAS - VALLE DEL CAUCA", text: "MALLAMAS - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - VALLE DEL CAUCA", text: "NUEVA EPS S.A. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - VALLE DEL CAUCA", text: "SALUD TOTAL S.A. E.P.S. - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "SALUDVIDA S.A. E.P.S", value: "SALUDVIDA S.A. E.P.S - VALLE DEL CAUCA", text: "SALUDVIDA S.A. E.P.S - VALLE DEL CAUCA"},
{departamento: "VALLE DEL CAUCA",		eps: "SAVIA SALUD EPS", value: "SAVIA SALUD EPS - VALLE DEL CAUCA", text: "SAVIA SALUD EPS - VALLE DEL CAUCA"},
{departamento: "VAUPES",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - VAUPES", text: "E.P.S. FAMISANAR LTDA. - VAUPES"},
{departamento: "VAUPES",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - VAUPES", text: "E.P.S. SANITAS S.A. - VAUPES"},
{departamento: "VAUPES",		eps: "MALLAMAS", value: "MALLAMAS - VAUPES", text: "MALLAMAS - VAUPES"},
{departamento: "VAUPES",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - VAUPES", text: "NUEVA EPS S.A. - VAUPES"},
{departamento: "VAUPES",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - VAUPES", text: "SALUD TOTAL S.A. E.P.S. - VAUPES"},
{departamento: "VICHADA",		eps: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.", value: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - VICHADA", text: "ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. - VICHADA"},
{departamento: "VICHADA",		eps: "E.P.S. FAMISANAR LTDA.", value: "E.P.S. FAMISANAR LTDA. - VICHADA", text: "E.P.S. FAMISANAR LTDA. - VICHADA"},
{departamento: "VICHADA",		eps: "E.P.S. SANITAS S.A.", value: "E.P.S. SANITAS S.A. - VICHADA", text: "E.P.S. SANITAS S.A. - VICHADA"},
{departamento: "VICHADA",		eps: "EPS SERVICIO OCCIDENTAL DE SALUD S.A.", value: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - VICHADA", text: "EPS SERVICIO OCCIDENTAL DE SALUD S.A. - VICHADA"},
{departamento: "VICHADA",		eps: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A", value: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - VICHADA", text: "EPS Y MEDICINA PREPAGADA SURAMERICANA S.A - VICHADA"},
{departamento: "VICHADA",		eps: "MALLAMAS", value: "MALLAMAS - VICHADA", text: "MALLAMAS - VICHADA"},
{departamento: "VICHADA",		eps: "NUEVA EPS S.A.", value: "NUEVA EPS S.A. - VICHADA", text: "NUEVA EPS S.A. - VICHADA"},
{departamento: "VICHADA",		eps: "SALUD TOTAL S.A. E.P.S.", value: "SALUD TOTAL S.A. E.P.S. - VICHADA", text: "SALUD TOTAL S.A. E.P.S. - VICHAD"}
]
